<template>
    <b-row no-gutters class="min-vh-100">
        <b-col xs="12" sm="12" md="3" lg="2">
            <accordion-product></accordion-product>
        </b-col>
        <b-col xs="12" sm="12" md="9" lg="10">
            <router-view></router-view>
        </b-col>
    </b-row>



</template>

<script>
import SiderMenuAccordion_deprecated from "@/components/general/elements/menus/SiderMenuAccordion_deprecated";
import AccordionProduct from "@/components/_public/_product/AccordionProduct";

export default {
  name: "PrlekijaContent",
  components: {AccordionProduct, SiderMenuAccordion_deprecated},
  props: {},
  data() {
    return {}
  },
  mounted() {
  },
  methods: {},
  computed: {},
  filters: {},
  watch: {}
}
</script>

<style scoped>

</style>
